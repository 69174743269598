import { SerializedNodes } from '@craftjs/core'
import isString from 'lodash/isString'
import isObject from 'lodash/isObject'
import { capitalCase } from 'change-case'
import { slateTextValues } from '../../data/SlateTextValues'

export const getFieldLabel = (data?: { props: Record<string, string>; custom?: Record<string, string> }) => {
  const { props = {}, custom = {} } = data || {}
  return (
    custom.internalLabel ||
    (isString(props.label) && props.label) ||
    (isObject(props.label) && slateTextValues(props.label).join('\n')) ||
    capitalCase(props.name || '')
  )
}

export function getNode(data: SerializedNodes, name: string) {
  return Object.values(data).find((v) => v.props.name === name)
}

export const getFieldLabelFromList = (name: string, data: SerializedNodes) => {
  return getFieldLabel(getNode(data, name))
}
