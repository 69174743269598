import { Router, useRouter } from 'next/router'
import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react'
import arrayMutators from 'final-form-arrays'
import useSnackbar from '../../lib/useSnackbar'
import { Form, FormProps, useFormState } from 'react-final-form'
import type { DocumentNode, ExecutionResult } from 'graphql'
import isString from 'lodash/isString'
import isFunction from 'lodash/isFunction'
import { Config, FormApi, Mutator, SubmissionErrors } from 'final-form'
import { FormSubmitButtonField } from '../formeditor/components/FormSubmitButton'
import createDecorator from 'final-form-focus'
import { useCommonTranslations } from './Translation'
import { useMutation } from '@apollo/client/react/hooks'
import type { MutationFunctionOptions } from '@apollo/client/react'
import { ResultOf, VariablesOf } from '@graphql-typed-document-node/core'
import { isPromise } from '../../data/types'
import { createContainer } from './unstated-next'
import { ApolloError } from '@apollo/client/errors'
import { PartialDeep } from 'type-fest'
import { useCalcDecorator } from './CalcDecorator'
import { Box } from '@material-ui/core'

type Impossible<K extends keyof any> = {
  [P in K]: never
}

export type PartialDeepNoExtra<T, U extends T = T> = PartialDeep<T> & Impossible<Exclude<keyof U, keyof T>>

export type FormWrapperOnSubmit<S extends DocumentNode> = (
  result: ExecutionResult<ResultOf<S>>
) => SubmissionErrors | Promise<SubmissionErrors | undefined> | undefined | void

type MyFormProps<S> = FormProps<VariablesOf<S>, PartialDeepNoExtra<VariablesOf<S>>>

export type FormWrapperRedirect<S extends DocumentNode> = (
  data: ExecutionResult<ResultOf<S>>
) => { href: string } | undefined
export type FormWrapperProps<S extends DocumentNode> = {
  mutation: S
  redirect?: false | string | FormWrapperRedirect<S>
  onSubmit?: FormWrapperOnSubmit<S>
  onSubmitError?: (error: unknown) => void
  onBeforeSubmit?: MyFormProps<S>['onSubmit']
  refetchQueries?: MutationFunctionOptions['refetchQueries']
  Buttons?: React.ComponentType<{ loading: boolean; disabled: boolean } & any>
  successSnackbar?: boolean | string
  processData?: (data: VariablesOf<S>) => VariablesOf<S>
  warnIfUnsavedChanges?: boolean
} & Omit<MyFormProps<S>, 'onSubmit'>

export const useAppForm = <S extends DocumentNode>({
  mutation,
  redirect = false,
  onSubmit: inputOnSubmit,
  refetchQueries,
  successSnackbar = true,
  processData,
  onBeforeSubmit,
  onSubmitError,
}: FormWrapperProps<S>) => {
  const [mutate] = useMutation<ResultOf<S>, VariablesOf<S>>(mutation)
  const snackbar = useSnackbar()
  const [loading, setLoading] = useState(false)
  const t = useCommonTranslations()

  const router = useRouter()
  const onSubmit: MyFormProps<S>['onSubmit'] = useCallback(
    async (inputValues, form, callback) => {
      if (onBeforeSubmit) {
        const res = onBeforeSubmit(inputValues, form, callback)
        const realRes = isPromise(res) ? await res : res
        if (realRes) {
          if (realRes.e) {
            return
          }
          return realRes
        }
      }

      if (!inputValues) return

      const data: VariablesOf<S> = processData ? processData(inputValues) : inputValues
      const errors = form.getState().errors

      console.log('Submit', data, errors)

      if (errors?.length) {
        snackbar.showWarning('Please fill out the required fields.')
        console.log(errors)
        return
      }

      try {
        setLoading(true)
        const result = await mutate({ variables: data, refetchQueries, awaitRefetchQueries: true })
        if (successSnackbar) {
          const message = isString(successSnackbar) ? successSnackbar : t.actions.saved
          snackbar.showSuccess(message)
        }

        if (inputOnSubmit) {
          const inputOnSubmitRes = inputOnSubmit(result)
          if (inputOnSubmitRes) return inputOnSubmitRes
        }

        if (redirect) {
          if (isFunction(redirect)) {
            const route = redirect(result)
            if (route) {
              await router.push(route.href, route.href)
            }
          } else {
            await router.push(redirect)
          }
        }
      } catch (e) {
        const graphQLError = e instanceof ApolloError && e?.graphQLErrors[0]
        if (onSubmitError) {
          setTimeout(() => onSubmitError(graphQLError || e))
        }
        if (graphQLError) {
          snackbar.showError(graphQLError.message || 'Sorry, something went wrong. Please try again later.')
          return graphQLError.extensions?.exception
        } else {
          return e
        }
      } finally {
        setLoading(false)
      }
    },
    [
      onBeforeSubmit,
      processData,
      snackbar,
      mutate,
      refetchQueries,
      successSnackbar,
      inputOnSubmit,
      redirect,
      t.actions.saved,
      router,
      onSubmitError,
    ]
  )

  return {
    onSubmit,
    loading,
  }
}

const defaultFocusDecorator = createDecorator()

export const FormWrapperContext = createContainer((props?: { submit: Config['onSubmit'] }) => {
  return {
    submit: props?.submit,
  }
})

export function FormWrapper<S extends DocumentNode>({
  children,
  Buttons = FormSubmitButtonField,
  decorators: inputDecorators = [],
  warnIfUnsavedChanges = false,
  ...props
}: FormWrapperProps<S> & {
  children: ReactNode
}) {
  const { onSubmit, loading } = useAppForm(props)
  const [outerSubmitting, setOuterSubmitting] = useState(false)

  const mutators = useMemo(() => {
    return { ...arrayMutators } as any as Record<string, Mutator<VariablesOf<S>, PartialDeep<VariablesOf<S>>>>
  }, [])
  const { CalculationContextProvider, decorator } = useCalcDecorator()
  const decorators = useMemo(() => {
    return [...inputDecorators, decorator]
  }, [inputDecorators, decorator])

  return (
    <Form<VariablesOf<S>, PartialDeep<VariablesOf<S>>>
      mutators={mutators}
      decorators={[defaultFocusDecorator, ...decorators]}
      {...props}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, submitting, values, form }) => {
        return (
          <CalculationContextProvider>
            <FormWrapperContext.Provider
              initialState={{
                submit: () => {
                  setOuterSubmitting(true)
                  setTimeout(
                    // TODO does this typing make sense?
                    () =>
                      onSubmit(values, form as any as FormApi<VariablesOf<S>, PartialDeep<VariablesOf<S>>>),
                    10
                  )
                },
              }}
            >
              <Box component={'form'} onSubmit={handleSubmit}>
                <WarnIfUnsaved enabled={warnIfUnsavedChanges && !submitting && !outerSubmitting} />
                {children}
                <Buttons loading={loading} />
              </Box>
            </FormWrapperContext.Provider>
          </CalculationContextProvider>
        )
      }}
    </Form>
  )
}

export const WarnIfUnsaved = ({
  enabled,
  onceDirtyAlwaysDirty = false,
}: {
  enabled: boolean
  onceDirtyAlwaysDirty?: boolean
}) => {
  const { dirty, submitting } = useFormState({ subscription: { dirty: true, submitting: true } })
  const [wasDirty, setWasDirty] = useState(false)
  useEffect(() => {
    if (dirty) {
      setWasDirty(true)
    }
    if (submitting) {
      setWasDirty(false)
    }
  }, [dirty, submitting])
  const isDirty = onceDirtyAlwaysDirty ? wasDirty : dirty

  useWarnIfUnsavedChanges(enabled && isDirty && !submitting)

  return null
}

export const useWarnIfUnsavedChanges = (unsavedChanges: boolean, inputMessage?: string) => {
  const t = useCommonTranslations()

  const message = inputMessage || t.ui.forms.confirmLeave
  const router = useRouter()

  useEffect(() => {
    const routeChangeStart = (url: string) => {
      if (router.asPath !== url && unsavedChanges && !confirm(message)) {
        Router.events.emit('routeChangeError')
        router.replace(router.asPath)
        throw 'Abort route change. Please ignore this error.'
      }
    }

    const beforeunload = (e: BeforeUnloadEvent) => {
      if (unsavedChanges) {
        e.preventDefault()
        e.returnValue = message
        return message
      }
    }

    window.addEventListener('beforeunload', beforeunload)
    Router.events.on('routeChangeStart', routeChangeStart)

    return () => {
      window.removeEventListener('beforeunload', beforeunload)
      Router.events.off('routeChangeStart', routeChangeStart)
    }
  }, [unsavedChanges, router, message])
}
