import React, { forwardRef, ReactNode } from 'react'
import { Box, ButtonProps } from '@material-ui/core'
import { useConnect } from './useConnect'
import { useFormState } from 'react-final-form'
import { AppUserComponent } from './AppUserComponent'
import { useCommonTranslations } from '../../common/Translation'
import { SubmitButton } from '../../common/SubmitButton'
import { useConferenceRegisterContext } from '../FormRegisterContext'

type FormSubmitButtonProps = ButtonProps & { label?: string }

export const FormSubmitButton = ({ label: inputLabel, ...props }: FormSubmitButtonProps) => {
  const t = useCommonTranslations()
  const label = inputLabel || t.actions.submit
  const { submitting } = useFormState({ subscription: { submitting: true } })
  return <SubmitButton {...props} loading={submitting} label={label} />
}

const FormButtonsWrapper = forwardRef<HTMLDivElement, { children: ReactNode }>(({ children }, ref) => {
  return (
    <div ref={ref}>
      <Box mt={6} mb={0} alignItems={'flex-end'} display={'flex'} flexDirection={'column'}>
        {children}
      </Box>
    </div>
  )
})

export const FormSubmitButtonField: AppUserComponent<FormSubmitButtonProps> = ({ ...props }) => {
  const connect = useConnect()

  const { submitButtons } = useConferenceRegisterContext()
  if (submitButtons) return <FormButtonsWrapper>{submitButtons}</FormButtonsWrapper>

  return (
    <FormButtonsWrapper ref={connect}>
      <FormSubmitButton {...props} />
    </FormButtonsWrapper>
  )
}

export const FloatingFormSubmitButtonField: AppUserComponent<FormSubmitButtonProps> = ({ ...props }) => {
  return (
    <Box sx={{ position: 'absolute', bottom: '0', width: '100%', left: 0 }}>
      <FormSubmitButtonField {...props} />
    </Box>
  )
}

FormSubmitButtonField.craft = {
  displayName: 'Submit Button',
  props: {
    label: 'Submit',
  },
}
