import React, { ReactNode, useCallback, useMemo, useRef, useState } from 'react'
import { createContainer } from '../common/unstated-next'
import { RegistrationFormFragment } from '../register/RegisterForm.generated'
import { AbstractGroupDataFragment } from '../../../pages/[conferenceName]/science/submit/[[...params]].generated'
import { Language } from '../../data/interfaces/Language'
import { RegConfigOnSubmit } from '../register/RegConfigOnSubmit'
import { LayoutContext } from '../layout/LayoutContext'
import { useRouter } from 'next/router'
import { ConferenceUserDataFragment, ShortConferenceDataFragment } from '../../data/fragments.generated'
import { FormWrapperProps } from '../common/FormWrapper'

// const { data } = useQuery(ConferenceUserDocument, {
//   variables: { conferenceName: useCurrentConferenceName() },
// })

const useConferenceContext = (props?: {
  conference?: ShortConferenceDataFragment
  user?: ConferenceUserDataFragment
  title?: string
  submitButtons?: ReactNode
  sciGroups?: AbstractGroupDataFragment[]
  language?: Language
}) => {
  const isPreview = useRouter()?.query?.preview
  const adminUser = LayoutContext.useContainer().adminUser

  return {
    conference: props?.conference,
    settings: props?.conference?.settings,
    scienceSettings: props?.conference?.scienceSettings,
    title: props?.title,
    user: isPreview ? undefined : props?.user,
    submitButtons: props?.submitButtons,
    sciGroups: props?.sciGroups,
    language: props?.language || props?.conference?.settings.language,
    isAdmin: !!adminUser,
    isPreview: !!isPreview,
    registrationData: props?.user?.registrationData,
    scienceData: props?.user?.scienceData,
    ...useOnBeforeSubmitContext(),
    ...props,
  }
}

export const ConferenceRegisterContext = createContainer(useConferenceContext)

export const ConferenceRegisterChildContextProvider: typeof ConferenceRegisterContext.Provider = ({
  children,
  initialState,
}) => {
  const parentContext = useConferenceRegisterContext()
  return (
    <ConferenceRegisterContext.Provider initialState={{ ...parentContext, ...initialState }}>
      {children}
    </ConferenceRegisterContext.Provider>
  )
}

const useFormContext = (props?: { form: RegistrationFormFragment }) => {
  return {
    form: props?.form,
    formSettings: props?.form.settings,
  }
}
export const FormRegisterContext = createContainer(useFormContext)

export const useFormRegisterContext = () => FormRegisterContext.useContainer()
export const useConferenceRegisterContext = () => ConferenceRegisterContext.useContainer()

const useOnBeforeSubmitContext = () => {
  const onBeforeSubmitRef = useRef<RegConfigOnSubmit | undefined>()
  const [error, setError] = useState<unknown>()

  const onBeforeSubmit: RegConfigOnSubmit = useCallback((values, form, callback) => {
    setError(undefined)
    return onBeforeSubmitRef.current?.(values, form, callback)
  }, [])
  return useMemo(
    () => ({
      onBeforeSubmit,
      onBeforeSubmitRef,
      error,
      setError,
    }),
    [error, onBeforeSubmit]
  )
}
