import { Node, NodeContext } from '@craftjs/core'
import { useContext } from 'react'
import { getFieldLabel } from '../getFieldLabel'

export const useConnect = () => {
  if (useContext(NodeContext)) {
    // eslint-disable-next-line @typescript-eslint/no-var-requires,@typescript-eslint/no-require-imports
    const { useNode, useEditor } = require('@craftjs/core')

    const {
      actions: { setProp, setCustom },
      connectors: { connect },
      id,
      props,
      displayName,
      node,
      nodeType,
      // eslint-disable-next-line react-hooks/rules-of-hooks
    } = useNode((node: Node) => ({
      props: node.data.props,
      displayName: node.data.custom.displayName,
      node,
      nodeType: node.data.type,
    }))

    const {
      actions: {
        history: { ignore },
      },
      // eslint-disable-next-line react-hooks/rules-of-hooks
    } = useEditor()

    if (node) {
      const label = getFieldLabel(props)

      if ((nodeType?.isField && !props.name) || props.name?.toLowerCase() === 'placeholder') {
        console.log('setting name for ' + id)
        ignore().setProp(id, (p: any) => {
          p.name = id
        })
      }

      if (label && displayName != label) {
        setCustom((c: any) => (c.displayName = label))
      }
    }

    return connect
  }

  return null
}
