import { PrefixedFieldValue, useNamePrefix } from './addNamePrefix'
import React, { useMemo } from 'react'
import { Field } from 'react-final-form'
import { useIsInDndContext } from '../../form/FormEditorDndContext'

export type RequireIf = {
  requireIf?: {
    enabled?: boolean
    field: string
    values?: any[]
  }
}

export const requireIfWrap =
  <T extends RequireIf>(C: React.ComponentType<T>): React.ComponentType<T> =>
  ({ requireIf, ...props }: T) => {
    if (!useIsInDndContext() && requireIf?.enabled && requireIf.field) {
      return (
        <PrefixedFieldValue name={requireIf.field}>
          {(value) => {
            if (!requireIf.values?.includes(value)) {
              return null
            }
            return <C {...(props as T)} />
          }}
        </PrefixedFieldValue>
      )
    } else {
      return <C {...(props as T)} />
    }
  }

export const prefixWrap =
  <T extends { name: string } & RequireIf>(C: React.ComponentType<T>) =>
  ({ name, ...props }: T) => {
    const prefixedName = useNamePrefix(name)
    const newProps = useMemo(() => ({ ...(props as T), name: prefixedName }), [props, prefixedName])

    const NewC = useMemo(() => requireIfWrap(C), [])

    return <NewC originalName={name} {...newProps} />
  }

export const PrefixedField = prefixWrap(Field)
