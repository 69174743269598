import { Decorator, getIn } from 'final-form'
import { Calculation, Updates } from 'final-form-calculate'
import React, { ReactNode, useContext, useEffect, useMemo } from 'react'

export const CalculationContext = React.createContext<{
  addCalculation?: (c: Calculation) => void
  removeCalculation?: (c: Calculation) => void
}>({})

export const useCalcDecorator = () => {
  return useMemo(() => {
    const props = createCalcDecorator()
    return {
      decorator: props.decorator,
      CalculationContextProvider: ({ children }: { children: ReactNode }) => (
        <CalculationContext.Provider value={props}>{children}</CalculationContext.Provider>
      ),
    }
  }, [])
}

export const useFormCalc = (calcs: Calculation | Calculation[]) => {
  const { removeCalculation, addCalculation } = useContext(CalculationContext)
  useEffect(() => {
    for (const calc of Array.isArray(calcs) ? calcs : [calcs]) {
      addCalculation?.(calc)
    }
    return () => {
      for (const calc of Array.isArray(calcs) ? calcs : [calcs]) {
        removeCalculation?.(calc)
      }
    }
  }, [addCalculation, calcs, removeCalculation])
}

export const createCalcDecorator = <T extends object>() => {
  const calculations: Set<Calculation> = new Set<Calculation>()
  const decorator: Decorator<T> = (form) => {
    let prevValues: T = form.getState().values
    const subscriber = (values: T) => {
      form.batch(() => {
        const doUpdate = async <T extends object>(
          complexKey: string,
          updates: Updates,
          isEqual: (
            a: GetFieldType<T, typeof complexKey>,
            b: GetFieldType<T, typeof complexKey>
          ) => boolean = (a, b) => a === b
        ) => {
          const currentValue = getIn(values, complexKey)
          const prevValue = getIn(prevValues || {}, complexKey)
          if (!isEqual(currentValue, prevValue)) {
            if (typeof updates === 'function') {
              const res = await updates(currentValue, complexKey, values, prevValues)
              for (const [field, value] of Object.entries(res)) {
                form.change(field as any, value as any)
              }
            } else {
              for (const [key, update] of Object.entries(updates)) {
                const value = await update(currentValue, values, prevValues)
                form.change(key as any, value)
              }
            }
          }
        }

        const allFields = form.getRegisteredFields()
        form.batch(() => {
          for (const { field, updates, isEqual } of calculations) {
            const fields = Array.isArray(field)
              ? field
              : field instanceof RegExp
              ? allFields.filter((v) => field.test(v))
              : [field]

            fields.forEach((v) => doUpdate(v, updates, isEqual))
          }
        })

        prevValues = values
      })
    }

    return form.subscribe(({ values }) => setTimeout(() => subscriber(values)), { values: true })
  }

  const addCalculation: (calc: Calculation) => void = (calc: Calculation) => {
    if (!calculations.has(calc)) {
      calculations.add(calc)
    }
  }
  const removeCalculation = (calc: Calculation) => {
    calculations.delete(calc)
  }

  return { addCalculation, removeCalculation, decorator }
}

type GetIndexedField<T, K> = K extends keyof T
  ? T[K]
  : K extends `${number}`
  ? '0' extends keyof T
    ? undefined
    : number extends keyof T
    ? T[number]
    : undefined
  : undefined

type FieldWithPossiblyUndefined<T, Key> = GetFieldType<Exclude<T, undefined>, Key> | Extract<T, undefined>

type IndexedFieldWithPossiblyUndefined<T, Key> =
  | GetIndexedField<Exclude<T, undefined>, Key>
  | Extract<T, undefined>

export type GetFieldType<T, P> = P extends `${infer Left}.${infer Right}`
  ? Left extends keyof T
    ? FieldWithPossiblyUndefined<T[Left], Right>
    : Left extends `${infer FieldKey}[${infer IndexKey}]`
    ? FieldKey extends keyof T
      ? FieldWithPossiblyUndefined<IndexedFieldWithPossiblyUndefined<T[FieldKey], IndexKey>, Right>
      : undefined
    : undefined
  : P extends keyof T
  ? T[P]
  : P extends `${infer FieldKey}[${infer IndexKey}]`
  ? FieldKey extends keyof T
    ? IndexedFieldWithPossiblyUndefined<T[FieldKey], IndexKey>
    : undefined
  : undefined
