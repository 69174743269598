import React, { useState } from 'react'

export enum EditorType {
  FORM,
  SCIENCE,
}

const useContext = ({ editorType, disabled }: { editorType: EditorType; disabled?: boolean }) => {
  const [referenceContainer, setReferenceContainer] = useState<HTMLElement | null>(null)
  const [preview, setPreview] = useState(false)
  return {
    referenceContainer,
    setReferenceContainer,
    preview,
    setPreview,
    editorType,
    disabled,
  }
}

export const Context = React.createContext<ReturnType<typeof useContext> | null>(null)

export function Provider({
  initialState,
  children,
}: {
  initialState: Parameters<typeof useContext>['0']
  children: React.ReactNode
}) {
  const value = useContext(initialState)
  return <Context.Provider value={value}>{children}</Context.Provider>
}

export const DndContext = { Provider }

export const useIsInDndContext = () => {
  const value = React.useContext(Context)
  return !!value && !value?.disabled
}

export const useDndContext = () => {
  const value = React.useContext(Context)
  if (value === null) {
    throw new Error('Component must be wrapped with <Container.Provider>')
  }
  return value
}

export const useIsDndPreviewMode = () => {
  return React.useContext(Context)?.preview || false
}
