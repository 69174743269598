import { createValidator, hasLengthGreaterThan, isRequired, matchesPattern } from 'revalidate'
import { useCommonTranslations } from '../../common/Translation'
import { FieldProps } from 'react-final-form'
import { FieldValidator } from 'final-form'
import { useMemo } from 'react'
import { TEXT_FIELD_TYPE } from '../../common/AppTextField'

const maxWords = (maxLength: number, message: string) =>
  createValidator(
    () => (value: string) => {
      if (value && value.split(' ').filter((v) => v).length > maxLength) {
        return message
      }
    },
    message
  )

const maxChars = (maxLength: number, message: string) =>
  createValidator(
    () => (value) => {
      if (value && value.length > maxLength) {
        return message
      }
    },
    message
  )

const minChars = (minLength: number, message: string) =>
  createValidator(
    () => (value) => {
      if (value && value.length < minLength) {
        return message
      }
    },
    message
  )

const minNumber = (minimum: number, message: string) =>
  createValidator(
    () => (value) => {
      if (value < minimum) {
        return message
      }
    },
    message
  )

const EMAIL_REGEX = /.+@.+\..+/i

export const useValidate = () => {
  const t = useCommonTranslations()
  return useMemo(
    () => ({
      isRequired: isRequired({ message: t.ui.validations.required }),
      hasMinimumLength: (length: number) =>
        hasLengthGreaterThan(length - 1)({ message: t.ui.validations.hasMinimumLength(length) }),
      minNumber: (length: number) => minNumber(length, t.ui.validations.minimum(length))(),
      maxWords: (length: number) => maxWords(length, t.ui.validations.wordsOrLess(length))(),
      minChars: (length: number) => minChars(length, t.ui.validations.charsAtLeast(length))(),
      maxChars: (length: number) => maxChars(length, t.ui.validations.charsOrLess(length))(),
      matchesPattern: (pattern: RegExp, message: string) => (s: string) =>
        matchesPattern(pattern)('Matches pattern')(s) && message,
    }),
    [t]
  )
}

// export const useTextValidatorsForFieldProps = ()
export const useTextValidators = ({
  minChars,
  maxChars,
  maxWords,
  inputLanguage,
  fieldProps,
  required,
  fieldType,
}: {
  maxWords?: number
  minChars?: number
  maxChars?: number
  inputLanguage?: 'EN' | 'HE'
  required?: boolean
  fieldProps?: Partial<FieldProps<any, any, any>>
  fieldType?: TEXT_FIELD_TYPE
}) => {
  const validate = useValidate()
  const t = useCommonTranslations()

  return useMemo(() => {
    const v = []
    if (minChars) {
      v.push(validate.minChars(minChars))
    }

    if (maxWords) {
      v.push(validate.maxWords(maxWords))
    }
    if (maxChars) {
      v.push(validate.maxChars(maxChars))
    }
    if (inputLanguage === 'EN') {
      v.push(validate.matchesPattern(/^[\w\s.\-`',]*$/, t.ui.validations.onlyEnglish))
    }
    if (inputLanguage === 'HE') {
      v.push(validate.matchesPattern(/^[\u0590-\u05FF\s.\-`',]*$/, t.ui.validations.onlyHebrew))
    }
    if (required) {
      v.push(validate.isRequired)
    }
    if (fieldType === 'email') {
      v.push(validate.matchesPattern(EMAIL_REGEX, t.ui.validations.email))
    }
    const combined = combineValidators(v)
    const validator: FieldValidator<any> = (...props) =>
      combined?.(...props) || fieldProps?.validate?.(...props)
    return validator
  }, [minChars, maxWords, maxChars, inputLanguage, required, fieldProps, validate, fieldType, t])
}

export const combineValidators = (validators: FieldValidator<any>[]): FieldValidator<any> | undefined => {
  if (!validators.length) return
  return (value, allValues) => {
    for (const val of validators) {
      const error = val(value, allValues)
      if (error) return error
    }
  }
}
