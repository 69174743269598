import isString from 'lodash/isString'
import React, { ReactNode, useCallback, useContext } from 'react'
import { Field, FieldProps, FieldRenderProps } from 'react-final-form'

export const FieldPrefixContext = React.createContext('')

export const useNamePrefix = (originalName: string) => {
  const context = useContext(FieldPrefixContext)
  const prefix = context && isString(context) ? context : ''
  return [prefix, originalName].filter((v) => v).join('.')
}

export const PrefixChildren = ({ prefix, children }: { prefix: string; children: ReactNode }) => {
  return <FieldPrefixContext.Provider value={prefix}>{children}</FieldPrefixContext.Provider>
}

export const ExtraPrefixChildren = ({ prefix, children }: { prefix: string; children: ReactNode }) => {
  return <PrefixChildren prefix={useNamePrefix(prefix)}>{children}</PrefixChildren>
}

// DONT UPGRADE FINAL FORM!!!!!!!!!!!
export const PrefixedField = <
  FieldValue extends any = any,
  RP extends FieldRenderProps<FieldValue, T> = FieldRenderProps<FieldValue, HTMLElement>,
  T extends HTMLElement = HTMLElement
>({
  name,
  ...props
}: FieldProps<FieldValue, RP, T>) => <Field<FieldValue, RP, T> name={useNamePrefix(name)} {...props} />

export const PrefixedFieldValue = <
  FieldValue extends any = string | Record<string, unknown> | Record<string, unknown>[] | string[] | undefined
>({
  name,
  children,
}: {
  name: string
  children: (v: FieldValue) => ReactNode
}) => {
  const callback = useCallback(({ input: { value } }: FieldRenderProps<any>) => children(value), [children])
  return (
    <PrefixedField name={name} subscription={{ value: true }}>
      {callback}
    </PrefixedField>
  )
}
