import { makeStyles, Theme } from '@material-ui/core/styles'
import React, { ReactNode } from 'react'
import { Button, ButtonProps, CircularProgress } from '@material-ui/core'
import { useCommonTranslations } from './Translation'

const useStyles = makeStyles((theme: Theme) => ({
  buttonProgress: {
    color: theme.palette.secondary.main,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}))

export const SpinningButton = ({
  loading = false,
  children,
  ...props
}: { loading?: boolean } & ButtonProps) => {
  const classes = useStyles()
  return (
    <Button disabled={loading} {...props}>
      {children}
      {loading ? <CircularProgress size={24} className={classes.buttonProgress} /> : null}
    </Button>
  )
}

export type SubmitButtonProps = { loading?: boolean; label?: ReactNode } & ButtonProps
export const SubmitButton = ({ loading = false, label: inputLabel, ...props }: SubmitButtonProps) => {
  const t = useCommonTranslations()
  const label = inputLabel || t.actions.submit
  return (
    <SpinningButton type="submit" variant="contained" color="primary" loading={loading} {...props}>
      {label}
    </SpinningButton>
  )
}
