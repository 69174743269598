import React, { ReactNode } from 'react'

import { FormHelperText, FormHelperTextProps } from '@material-ui/core'
import { FieldMetaState, useField } from 'react-final-form'
import isObject from 'lodash/isObject'
import uniq from 'lodash/uniq'

export interface ErrorMessageProps {
  showError: boolean
  meta: FieldMetaState<any>
  formHelperTextProps?: Partial<FormHelperTextProps>
  helperText?: ReactNode
}

export function ErrorMessage({ showError, meta, formHelperTextProps, helperText }: ErrorMessageProps) {
  if (showError) {
    const message = isObject(meta.error) ? uniq(Object.values(meta.error)).join(', ') : meta.error?.toString()
    return <FormHelperText {...formHelperTextProps}>{message || meta.submitError}</FormHelperText>
  } else if (helperText) {
    return <FormHelperText {...formHelperTextProps}>{helperText}</FormHelperText>
  } else {
    return null
  }
}

export interface showErrorProps {
  meta: FieldMetaState<any>
}

const config = {
  subscription: {
    error: true,
    submitError: true,
    dirtySinceLastSubmit: true,
    touched: true,
    modified: true,
  },
}

export function useFieldForErrors(name: string) {
  return useField(name, config)
}

export function showError({
  meta: { submitError, dirtySinceLastSubmit, error, touched, modified },
}: showErrorProps) {
  return !!(((submitError && !dirtySinceLastSubmit) || error) && (touched || modified))
}
export type ShowErrorFunc = (props: ShowErrorProps) => boolean

export const showErrorOnChange: ShowErrorFunc = ({
  meta: { submitError, dirtySinceLastSubmit, error, touched, modified },
}: ShowErrorProps) => !!(((submitError && !dirtySinceLastSubmit) || error) && (touched || modified))

export interface ShowErrorProps {
  meta: FieldMetaState<any>
}
