import { SlateInput } from './interfaces/SlateInput'
import isString from 'lodash/isString'
import { Node, Text, Element } from 'slate'
import { Mention } from '../components/slate/Mention'

export const slateTextValues = (e?: SlateInput | string): string[] => {
  if (!e) return []
  return isString(e) ? [e] : (Array.isArray(e) ? e : [e]).map((v) => Node.string(v))
}

export const slateTextValuesWithMentions = (
  node: SlateInput,
  renderMention: (mention: Mention) => string
): string[] => {
  if (Array.isArray(node)) {
    return node.flatMap((v) => slateTextValuesWithMentions(v, renderMention))
  }

  if ('type' in node && node.type === 'mention') {
    return [renderMention(node.mention as Mention)]
  }

  if (Text.isText(node)) {
    return [node.text || '']
  }

  return 'children' in node
    ? (node.children as SlateInput[])?.flatMap((n) => slateTextValuesWithMentions(n, renderMention)) || []
    : []
}
